//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-288:_6758,_9352,_6180,_1180,_5764,_1464,_2612,_9520;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-288')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-288', "_6758,_9352,_6180,_1180,_5764,_1464,_2612,_9520");
        }
      }catch (ex) {
        console.error(ex);
      }